import clsx from 'clsx';
import { motion } from 'framer-motion';

import { RaffleLogoText } from './RaffleLogo';

type LoadingSpinnerProps = {
  className?: string;
  svgClassName?: string;
  animation?: Record<string, any>;
};

export const LoadingSpinner = ({
  className,
  svgClassName,
  animation,
}: LoadingSpinnerProps) => (
  <motion.div
    className={clsx(
      'flex items-center justify-center p-16 text-dark-accent',
      className,
    )}
    {...animation}
  >
    <svg
      height="20"
      width="20"
      className={clsx('-ml-1 mr-3 h-8 w-8 animate-spin', svgClassName)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  </motion.div>
);

export default LoadingSpinner;

export const RaffleLoadingSpinner = ({
  className,
  svgClassName,
  animation,
}: LoadingSpinnerProps) => (
  <motion.div
    className={clsx(
      'relative flex items-center justify-center p-16 text-white',
      className,
    )}
    {...animation}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="250"
      height="250"
      viewBox="0 0 250 250"
      fill="none"
      className={clsx('absolute h-64 w-64 animate-spin', svgClassName)}
    >
      <path
        opacity="0.2"
        d="M250 125C250 194.036 194.036 250 125 250C55.9644 250 0 194.036 0 125C0 55.9644 55.9644 0 125 0C194.036 0 250 55.9644 250 125ZM6.20893 125C6.20893 190.606 59.3935 243.791 125 243.791C190.606 243.791 243.791 190.606 243.791 125C243.791 59.3935 190.606 6.20893 125 6.20893C59.3935 6.20893 6.20893 59.3935 6.20893 125Z"
        fill="#78778D"
      />
      <path
        d="M198.815 222.013C199.851 223.375 199.589 225.321 198.202 226.323C177.63 241.186 152.997 249.45 127.575 249.973C102.152 250.497 77.1992 243.255 56.0325 229.252C54.6058 228.308 54.2639 226.374 55.2428 224.971C56.2217 223.568 58.1505 223.228 59.5784 224.17C79.6687 237.424 103.335 244.277 127.447 243.78C151.559 243.283 174.923 235.462 194.45 221.392C195.838 220.392 197.779 220.652 198.815 222.013Z"
        fill="#33333C"
      />
    </svg>

    <div className="flex h-48 w-48 animate-jump items-center justify-center rounded-full bg-primary-600 animate-delay-500 animate-normal animate-duration-1000 animate-fill-both animate-infinite animate-ease-in-out">
      <RaffleLogoText white />
    </div>
  </motion.div>
);

export const DataLoadingAnimation = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#6333CC"
    fill="#6333CC"
  >
    <rect x="1" y="6" width="2.8" height="12">
      <animate
        begin="spinner_Diec.begin+0.4s"
        attributeName="y"
        calcMode="spline"
        dur="0.6s"
        values="6;1;6"
        keySplines=".14,.73,.34,1;.65,.26,.82,.45"
      />
      <animate
        begin="spinner_Diec.begin+0.4s"
        attributeName="height"
        calcMode="spline"
        dur="0.6s"
        values="12;22;12"
        keySplines=".14,.73,.34,1;.65,.26,.82,.45"
      />
    </rect>
    <rect x="5.8" y="6" width="2.8" height="12">
      <animate
        begin="spinner_Diec.begin+0.2s"
        attributeName="y"
        calcMode="spline"
        dur="0.6s"
        values="6;1;6"
        keySplines=".14,.73,.34,1;.65,.26,.82,.45"
      />
      <animate
        begin="spinner_Diec.begin+0.2s"
        attributeName="height"
        calcMode="spline"
        dur="0.6s"
        values="12;22;12"
        keySplines=".14,.73,.34,1;.65,.26,.82,.45"
      />
    </rect>
    <rect x="10.6" y="6" width="2.8" height="12">
      <animate
        id="spinner_Diec"
        begin="0;spinner_dm8s.end-0.1s"
        attributeName="y"
        calcMode="spline"
        dur="0.6s"
        values="6;1;6"
        keySplines=".14,.73,.34,1;.65,.26,.82,.45"
      />
      <animate
        begin="0;spinner_dm8s.end-0.1s"
        attributeName="height"
        calcMode="spline"
        dur="0.6s"
        values="12;22;12"
        keySplines=".14,.73,.34,1;.65,.26,.82,.45"
      />
    </rect>
    <rect x="15.4" y="6" width="2.8" height="12">
      <animate
        begin="spinner_Diec.begin+0.2s"
        attributeName="y"
        calcMode="spline"
        dur="0.6s"
        values="6;1;6"
        keySplines=".14,.73,.34,1;.65,.26,.82,.45"
      />
      <animate
        begin="spinner_Diec.begin+0.2s"
        attributeName="height"
        calcMode="spline"
        dur="0.6s"
        values="12;22;12"
        keySplines=".14,.73,.34,1;.65,.26,.82,.45"
      />
    </rect>
    <rect x="20.2" y="6" width="2.8" height="12">
      <animate
        id="spinner_dm8s"
        begin="spinner_Diec.begin+0.4s"
        attributeName="y"
        calcMode="spline"
        dur="0.6s"
        values="6;1;6"
        keySplines=".14,.73,.34,1;.65,.26,.82,.45"
      />
      <animate
        begin="spinner_Diec.begin+0.4s"
        attributeName="height"
        calcMode="spline"
        dur="0.6s"
        values="12;22;12"
        keySplines=".14,.73,.34,1;.65,.26,.82,.45"
      />
    </rect>
  </svg>
);
